<template>
  <div class="subscriber-editor-create">
    <div class="subscriber-editor-create-background" @click="$router.push('/subscribers')" />
    <div class="subscriber-editor-create-container">
      <div class="subscribers-bulk-upload-container" v-loading="isFormProcessing">
        <div class="subscriber-bulk-upload-header">
          <div class="subscriber-download-container">
            <a
              href="https://docs.google.com/spreadsheets/d/1TjsIJljQPVjGqGPSOIGIKTAPp9okrEIEgcwKbEpUpsc/edit?usp=sharing"
              target="_blank"
            >
              Download template
            </a>
          </div>
          <div class="subscriber-switch-container">
            <span>Update if mobile number matches one in database</span>
            <label class="subscriber-form-checkbox">
              <input type="checkbox" v-model="updateOnMatch" />
              <div class="subscriber-form-checkbox-slider" />
            </label>
          </div>
          <div class="subscriber-buttons-container">
            <div class="subscriber-buttons-cancel" @click="handleCancelClick">Cancel</div>
            <div
              class="subscriber-buttons-import"
              :class="{ disabled: importButtonDisabled }"
              @click="handleImportClick"
            >
              Import
            </div>
          </div>
        </div>
        <div class="subscriber-bulk-upload-content">
          <div v-if="showUploadEnabledTitle && !isFormProcessing" class="subscriber-form-title">
            Drag and drop your completed template anywhere in this grey area to upload
          </div>
          <div v-if="showUploadFile && !isFormProcessing" class="subscriber-form-title">
            {{ file.name.slice() }}
          </div>
          <div v-if="isFormProcessing" class="subscriber-form-title">Uploading...</div>
          <div class="subscriber-form-wrapper">
            <el-upload ref="upload" drag :http-request="onSubmit" action="">
              <div v-if="errorLog.length > 0" class="subscriber-form-errors">
                <div
                  v-for="(error, index) in errorLog"
                  :key="'error-log-' + index"
                  class="subscriber-form-errors-item"
                >
                  Row {{ error.row }}: {{ error.msg }}
                </div>
                <div class="subscriber-form-errors-message">
                  Upload completed with errors. Please fix the above errors and drag your updated
                  spreadsheet into this area to try again.
                </div>
              </div>
            </el-upload>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Excel from "exceljs";
import firebase from "firebase";

export default {
  name: "SubscriberBulkUpload",
  data() {
    return {
      importedRows: 0,
      file: "",
      updateOnMatch: true,
      isFormProcessing: false,
      expectedHeader: [
        "first_name",
        "last_name",
        "email",
        "phone_number",
        "gender",
        "date_of_birth",
        "notifications",
        "allergies",
        "medical_conditions",
      ],
      required: ["first_name", "last_name", "email", "phone_number", "date_of_birth", "gender"],
      errorLog: [],
    };
  },
  computed: {
    showUploadFile() {
      return this.errorLog.length === 0 && this.file !== "";
    },
    clubId() {
      return this.$store.getters["clubId"];
    },
    showUploadEnabledTitle() {
      return this.errorLog.length === 0 && !this.isFormProcessing && this.file === "";
    },
    bulkDialogVisible() {
      return this.$route.path.includes("bulk-upload");
    },
    importButtonDisabled() {
      if (this.file === "" || this.errorLog.length > 0) {
        return true;
      }

      if (this.file !== "" && this.errorLog.length === 0) {
        return false;
      }

      return true;
    },
  },
  methods: {
    handleCancelClick() {
      this.$router.push("/subscribers");
    },
    handleImportClick() {
      this.handleImport(this.file);
    },
    beforeClose(done) {
      this.$router.push("/subscribers");
      done();
    },
    onSubmit(req) {
      this.isFormProcessing = false;
      this.file = "";
      this.errorLog = [];
      this.file = req.file;
    },
    validHeader(values) {
      // Check if all header items exist and are in the same order
      for (var i = 0; i < this.expectedHeader.length; i++) {
        if (values[i] !== this.expectedHeader[i]) {
          return false;
        }
      }
      return true;
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    validatePhone(phone) {
      let re = /^\+?[1-9]\d{1,14}$/;
      return re.test(phone);
    },
    validRow(values, rowIndex) {
      let errorLog = [];
      for (let i = 0; i < this.expectedHeader.length; i++) {
        const rowHeader = this.expectedHeader[i];
        const isEmpty = typeof values[i] === "undefined";
        const isRequired = this.required.includes(rowHeader);
        // Check if the value is empty, and if its required
        console.log(values);
        if (isEmpty && isRequired) {
          errorLog.push({ row: rowIndex, msg: `Error mandatory field ${rowHeader} missing.` });
        }
        if (typeof values[i] === "object" && values[i] !== null) {
          errorLog.push({
            row: rowIndex,
            msg: `Error ${rowHeader} ${values[i]} check special characters are correctly escaped.`,
          });
        }
        if (isEmpty) {
          // Skip Item
          continue;
        }
        if (rowHeader === "email" && !this.validateEmail(values[i])) {
          errorLog.push({
            row: rowIndex,
            msg: `Error ${values[i]} is not a valid ${rowHeader} field`,
          });
        } else if (rowHeader === "phone_number" && !this.validatePhone(values[i])) {
          errorLog.push({
            row: rowIndex,
            msg: `Error ${values[i]} ${rowHeader} is not a valid international phone number`,
          });
        }
      }
      this.errorLog.push(...errorLog);
      if (errorLog.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    chunkSheet(file) {
      // Helper Max chunk size for firestore document batching is 500
      const chunk = function (arr, size = 499) {
        let newarr = [];
        for (let i = 0; i < arr.length / size; i++) newarr[i] = [];
        for (let j = 0; j < arr.length; j++) newarr[Math.floor(j / size)][j % size] = arr[j];
        return newarr;
      };
      return new Promise((resolve) => {
        const wb = new Excel.Workbook();
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
          const buffer = reader.result;
          wb.xlsx.load(buffer).then((workbook) => {
            workbook.eachSheet((sheet, id) => {
              let validRows = [];
              let validHeader = true;
              sheet.eachRow((row, rowIndex) => {
                let r = row.values;
                r.shift(); // Sheets are 1 indexed, first entry is empty
                // Check Header Matches
                if (id === 1 && rowIndex == 1) {
                  if (!this.validHeader(r)) {
                    this.errorLog.push({
                      row: rowIndex,
                      msg: `Upload Aborted. Error invalid header row. Check template.`,
                    });
                    validHeader = false;
                  }
                } else {
                  // Validate rows
                  if (this.validRow(r, rowIndex)) {
                    this.importedRows++;
                    validRows.push(r);
                  }
                }
              });
              if (validHeader) {
                resolve(chunk(validRows));
              }
            });
          });
        };
      });
    },
    async handleImport(file) {
      // Bulk Upload the batch interface.
      // This could be refactored by currently we overwrite get() on the collection in the datastore.
      const db = firebase.app().firestore();
      // const docRef = db.collection('users').doc()
      const batchChunks = await this.chunkSheet(file);

      // Commit the batches
      this.isFormProcessing = true;
      let batchedAmount = 0;
      batchChunks.map(async (chunk) => {
        const batch = db.batch();
        await Promise.all(
          chunk.map(async (row) => {
            // transform row into json object
            let rawJSON = this.expectedHeader.reduce((result, headerName, index) => {
              if (typeof row[index] === "undefined") {
                result[headerName] = "";
              } else {
                result[headerName] = row[index];
              }
              return result;
            }, {});

            const computedJSON = JSON.parse(JSON.stringify(rawJSON));
            delete computedJSON.medical_conditions;
            delete computedJSON.allergies;
            delete computedJSON.notifications;

            const clubSpecific = {};
            if (rawJSON.notifications !== "") {
              clubSpecific.notifications = rawJSON.notifications.split(",");
            }

            if (rawJSON.medical_conditions !== "") {
              clubSpecific.medical_conditions = rawJSON.medical_conditions.split(",");
            }

            if (rawJSON.allergies !== "") {
              clubSpecific.allergies = rawJSON.allergies.split(",");
            }

            if (this.updateOnMatch) {
              let snapshot = await db
                .collection("users")
                .where("phone_number", "==", rawJSON.phone_number)
                .get();
              if (!snapshot.empty) {
                db.collection("users")
                  .doc(snapshot.docs[0].id)
                  .update({ ...computedJSON })
                  .then(() => {
                    db.collection("users")
                      .doc(snapshot.docs[0].id)
                      .collection("club_specific")
                      .doc(this.clubId)
                      .get()
                      .then((data) => {
                        if (data.exists) {
                          db.collection("users")
                            .doc(snapshot.docs[0].id)
                            .collection("club_specific")
                            .doc(this.clubId)
                            .set(clubSpecific);
                        } else {
                          db.collection("users")
                            .doc(snapshot.docs[0].id)
                            .collection("club_specific")
                            .doc(this.clubId)
                            .update(clubSpecific);
                        }
                      });
                  });
              } else {
                db.collection("users")
                  .add({ ...computedJSON })
                  .then((data) => {
                    db.collection("users")
                      .doc(data.id)
                      .collection("club_specific")
                      .doc(this.clubId)
                      .set(clubSpecific);
                  });
              }
            } else {
              db.collection("users")
                .add({ ...computedJSON })
                .then((data) => {
                  db.collection("users")
                    .doc(data.id)
                    .collection("club_specific")
                    .doc(this.clubId)
                    .set({
                      notifications: rawJSON.notifications,
                      medical_conditions: rawJSON.medical_conditions,
                      allergies: rawJSON.allergies,
                    });
                });
            }
          })
        );
        batch.commit().then(() => {
          batchedAmount = batchedAmount + 1;
          if (batchedAmount === batchChunks.length) {
            this.isFormProcessing = false;
            this.$router.push("/subscribers");
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.subscriber-editor-create {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: scroll;
  padding: 135px 0;
}

.subscriber-editor-create-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #171d33;
  opacity: 0.8;
}

.subscriber-editor-create-close {
  position: absolute;
  top: -64px;
  cursor: pointer;
  right: 0;
}

.subscriber-editor-create-container {
  z-index: 1;
  position: relative;
  width: 1110px;
  margin: 0 auto;
  background: #fff;
  border-radius: 6px;
  padding: 18px 24px 24px;
}

.subscribers-bulk-upload-container {
  :deep(.el-dialog) {
    // width: 100% !important;
    border-radius: 8px;
  }

  .subscriber-bulk-upload-header {
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .subscriber-download-container {
    flex: 1;
  }

  .subscriber-download-container a {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: -0.5px;
    text-decoration-line: underline;
    color: var(--text-dark-blue-3);
  }

  .subscriber-switch-container {
    flex: 2;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  .subscriber-switch-container span {
    margin-right: 22px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: -0.5px;
    color: #000000;
  }

  .subscriber-buttons-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  .subscriber-bulk-upload-content {
    position: relative;
  }

  .subscriber-form-title {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 348px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.5px;
    color: var(--text-color-dark-1);
    z-index: 1;
    pointer-events: none;
  }

  .subscriber-form-wrapper {
    width: 100%;
    height: 100%;
    min-height: 400px;
    border-radius: 8px;
    background: #eeeeee;
  }

  :deep(.el-upload-dragger) {
    background: transparent;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  :deep(.el-upload-list) {
    display: none;
  }

  :deep(.el-button--success[disabled="true"]) {
    background: #eeeeee;
    border-color: #eee;
    color: var(--text-light-grey-4);
  }

  .subscriber-form-errors {
    position: absolute;
    width: 100%;
    max-height: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    text-align: left;
    top: 0;
    left: 0;
    padding: 32px 25px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: -0.5px;
    color: var(--text-color-dark-1);
    z-index: 1;
  }

  .subscriber-form-errors-item {
    color: var(--danger-color);
  }

  .subscriber-form-errors-message {
    margin-top: 16px;
  }
}

.subscriber-form-checkbox {
  cursor: pointer;
  display: flex;
  position: relative;
}

.subscriber-form-checkbox span {
  margin-left: 36px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #111111;
}

.subscriber-form-checkbox-slider {
  background-color: #fff;
  border: 2px solid #f0f1f4;
  transition: 0.4s;
  display: block;
  width: 48px;
  height: 24px;
  border-radius: 100px;
}

.subscriber-form-checkbox-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: #f0f1f4;
  border-radius: 50%;
  transition: 0.4s;
}

.subscriber-form-checkbox input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.subscriber-form-checkbox input:checked + .subscriber-form-checkbox-slider {
  background-color: #0ba3a9;
  border-color: transparent;
}

.subscriber-form-checkbox input:focus + .subscriber-form-checkbox-slider {
  box-shadow: 0 0 1px #0ba3a9;
}

.subscriber-form-checkbox input:checked + .subscriber-form-checkbox-slider:before {
  transform: translateX(24px);
  background-color: #fff;
}

.subscriber-buttons-cancel {
  margin-right: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #f7685b;
  border-radius: 6px;
  padding: 4px 24px;
  cursor: pointer;
}

.subscriber-buttons-import {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #0ba3a9;
  border-radius: 6px;
  padding: 4px 24px;
  cursor: pointer;
  transition: background 0.2s ease, color 0.2s ease;
  will-change: background, color;
}

.subscriber-buttons-import.disabled {
  background: #e8e9ec;
  color: #a6aab4;
  pointer-events: none;
}
</style>
